import React, { useEffect, useState } from "react";
import "./form.scss";
import { useParams } from "react-router-dom";
import screenView from "../../api/screenview";
import Logo from "../../images/walkthru_image.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";


let isSimple  =false
const Form = () => {
  let { id }: any = useParams();
  const [articles,setArticles] = useState<any>()
  const [loader,setLoader] = useState(true)
  const [isSubmitting,setIsSubmitting] = useState(false)
  const [isGenerated,setIsGenerated] = useState(false)
  const [isCustomer,setIsCustomer] = useState(false)
  const [reportId,setReportId] = useState("")

  const [formData,setFormData] = useState<any>({
    name:"",
    template:"",
  })
  const [cusFormData, setCusFormData] = useState<any>({
    name:"",
    email:"",
    phone:""
  })
  const [answer,setAnswer] = useState<any>({})
  useEffect(() =>{
    setLoader(true)
    getFormQuestion()
  },[])

  const getFormQuestion = async () => {
    const data: any = await screenView<any>("GET", `users/shared-templates/${id}`);
    const response = data?.responseObj?.data;
    const {template, customer} = response;
    setArticles(template?.articles);
    setCusFormData({name:customer?.name, email: customer?.email,phone:`1${customer?.contactNumber}`})
    console.log(template?.articles, "customer");
    
    isSimple = template?.articles?.some((item:any) => {
      return item.section.type !== 'simple';
    });
    console.log(isSimple, "isSimple");
    
    setLoader(false) 
    let ans:any = {};
    template?.articles?.forEach((answ:any) => {
      ans[answ._id] = "";
    });
    setAnswer(ans)
  };

  const handleFormChange = (e: any) => {
    const {name, value} = e.target    
    setCusFormData((preState:any) => ({...preState,[name]: value}));
  }

  const handlePhoneChange = (e: any) => {
    setCusFormData((preState:any) => ({...preState,'phone': e}))
  }

  const handleCustomerSubmit = (e: any) => {
    e.preventDefault();
    setIsCustomer(true);
  }

  const handleGenerate = async(e:any) =>{
    setIsSubmitting(true)
    e.preventDefault()
    const body = {
      name: cusFormData.name,
      template: id,
      answers:answer
    }
    console.log(body,"bodybodybody");
    
    const data = await screenView<any>("POST", `solutions`,body)
   console.log(data,"screenViewscreenViewscreenView");
   setReportId(data?.responseObj?.data?._id)
   setIsSubmitting(false)
   setIsGenerated(true)
   
      }
      const handleChange = (event:any) =>{
        const {value,name} = event.target
        setFormData((prevState:any) => ({ ...prevState, [name]: value }));
      }

      const handleSelect = (event:any,id:any) =>{
        const {value} = event.target
        setAnswer((prevState:any) => ({ ...prevState, [id]: value }));
      }

  return (
    <div className="form-bg-color">
      <div className="form-container">
        <div
          className="center-content"
          style={{ position: "sticky", top: 0, background: "#fff" }}
        >
          <img
            src={Logo}
            alt="logo"
            style={{ width: "150px", objectFit: "scale-down" }}
          />
        </div>
        {isSubmitting && !isGenerated &&<div style={{display:"flex",justifyContent:"center",alignItems:"center",height:500}}>
          <div className="text-card-layout" style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",gap:10,width:"100%",padding:"1.5rem"}} >  <h5 className="question-title">Generating Report...</h5> 
          <h5 className="question-title" style={{fontWeight:500,color:"#828282"}}>Please wait a second, your report will be generated shortly</h5> 
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div> </div>}
          {!isSubmitting && isGenerated && <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:500}}>
          <div className="text-card-layout" style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",gap:20,width:"100%"}} >  <p className="question-title">Your personalized walkthru is now ready!</p> 
          <h5 className="question-title" style={{fontWeight:500,color:"#828282"}}>Click View to start exploring your awesome video walkthru.</h5> 
          <a
            href={`${process.env.REACT_APP_API_REPORT_HOST}/walkthru/${reportId}/view`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "inline-block",
              backgroundColor: "#1396E4",
              color: "#fff",
              width: "100%",
              padding: "15px",
              textDecoration: "none",
              textAlign: "center",
            }}
          >
            View Walkthru
          </a>
         </div></div>}
      {loader  && !isSubmitting &&  !isGenerated && 
        <>
          <div className="text-body-loader"/>
          <div style={{display:"flex",flexDirection:"column",gap:20}}>
            <div className="header-loader"/>
            <div className="input-loader"/>
          </div>
          <div className="header-loader"/>
          <div className="card-loader"/>
          <div className="card-loader"/>
          <div className="card-loader"/>
          <div className="card-loader"/>
        </>
      }
      {isCustomer && !isSubmitting && !isGenerated &&
      <form onSubmit={(e)=>handleGenerate(e)} style={{height:"100%",display:"flex",flexDirection:"column",justifyContent:"space-between",gap:30,flex:1}}>
        <div style={{display:"flex",flexDirection:"column",gap:20}}>
        <p className="text-body">
        Answer few articles to get your video walkthru
        </p>
        {/* <div style={{display:"flex",flexDirection:"column",gap:10}}>
          <h5>Enter Your Details</h5>
          <input name="name" placeholder="Enter your name" style={{padding:15}} required onChange={handleChange} />
        </div> */}
        {/* <h5 style={{ color: "#0252B3" }}>Response</h5> */}
        <>        
        {articles.map((ques:any, i:number) => {
          return (
            <div key={i} >
            {ques?.section?.type === "multi" && (   <div className="card-layout">
              <p className="question-title">{ques.title}</p>
                <select
                  name={ques.title}
                  required
                  placeholder="Select Item"
                  style={{ backgroundColor: "transparent" }}
                  onChange={(e) => handleSelect(e,ques._id)}
                  value={answer[ques._id] || ""}
                >
                {answer[ques._id] ? null : <option value="">Select Item</option>}
                 { ques?.section?.options?.map((opt:any,ind:number) =>{
                  return(
                    <option key={ind} value={opt?.name}>{opt?.name}</option>
                  )
                })}
                  </select>
                  </div>
                  )
                 }</div>
          )
        })}
        </>
        </div>
        <div className="form-btn">
          <button type="submit" style={{backgroundColor:"#1396E4",color:"#fff",width:"100%",padding:15}}>Generate</button>
        </div>
      </form>}
      {
        !loader && !isCustomer && !isSubmitting && !isGenerated && 
        <form onSubmit={(e)=>{isSimple ? handleCustomerSubmit(e): handleGenerate(e)}} style={{height:"100%",display:"flex",flexDirection:"column",justifyContent:"space-between",gap:30,flex:1}}>
          <div>
                       <h6 style={{fontSize: '21px', fontWeight: 600}}>Customer Details</h6>
            <div style={{paddingTop: '25px',display:'flex', flexDirection: 'column', gap: '20px'}}>
              <div style={{flex:1,display: 'flex',flexDirection: 'column',gap:'5px'}}>
                <h6>Enter your name</h6>
                <input name="name" readOnly type="text" style={{padding:15}} value={cusFormData?.name} onChange={handleFormChange}/>
              </div>
              <div style={{flex:1,display: 'flex',flexDirection: 'column',gap:'5px'}}>
                <h6>Enter your email</h6>
                <input name="email" type="email" style={{padding:15}} required={cusFormData?.phone.length == 1 } value={cusFormData?.email} onChange={handleFormChange}/>
              </div>
              <div style={{flex:1,display: 'flex',flexDirection: 'column',gap:'5px'}}>
                <h6>Enter your phone</h6>
                <PhoneInput
                  country={"us"}
                  value={cusFormData?.phone}
                  specialLabel=""
                  countryCodeEditable={false}
                  enableClickOutside={true}
                  inputProps={{
                    name: "phone",
                    required: true,
                    style: {
                      width: "100%",
                      height: "55px"
                    },
                  }}
                  onChange={handlePhoneChange}
                  onlyCountries={["ca", "us"]}
                />
              </div>
            </div>
          </div>
          <div className="form-btn">
          <button type="submit" style={{backgroundColor:"#1396E4",color:"#fff",width:"100%",padding:15}}>{isSimple ? 'Next' :'Generate'}</button>
        </div>
        </form>
      }
      </div>
    </div>
  );
};

export default Form;
