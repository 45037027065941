export async function fileUpload(requestBody) {
  let reqHeaders = {
    // You might need to remove the "Content-Type" header for multipart forms
    // "Content-Type": "multipart/form-data",
  };

  try {
    const response = await fetch(process.env.REACT_APP_API_SCREEN_URL + `/users/media`, {
      method: "PUT",
      body: requestBody,
      headers: reqHeaders,
    });

    if (response.ok) {
      return response.json();
    } else {
      const errorMessage = [response.status, await response.json()];
      throw errorMessage;
    }
  } catch (error) {
    throw error;
  }
}
