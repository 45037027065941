export const URL = {
  SUCCESS: "/payment/success",
  FAILED: "/payment/failed",
  EXPLAINER_STORY: "/explainer/:id",
  WALKTHRU_STORY: "/walkthru/:id/view",
  LAB_STORY: "/lab/:id",
  TECHNICIAN_JOIN: "/invitations/:id/join",
  CUSTOMER_FORM: "/answers/:id/submit",
  QUESTIONS_FORM: "/questions/:id/submit",
  RESPONSE_SUCCESS: "/response/success",
  EXPIRED: "/:id/expired",
  UNKNOWN: "/:id/not-found",
  QRCODE: "/:id",
  VIDEOBYTE: "/walkthru/:id",
};
