import React, { useContext, useState, useEffect, useRef } from "react";
import Progress from "./Progress";
import {
  ProgressContext,
  GlobalCtx,
  StoriesContext as StoriesContextInterface,
} from "../../../interfaces";
import ProgressCtx from "../context/Progress";
import GlobalContext from "../context/Global";
import StoriesContext from "../context/Stories";

const ProgressArray = () => {
  const [count, setCount] = useState<number>(0);
  const { currentId, next, videoDuration, pause, bufferAction } = useContext<ProgressContext>(
    ProgressCtx
  );

  
  const prevPause = useRef<any>();
  const {
    defaultInterval,
    onStoryEnd,
    onStoryStart,
    onAllStoriesEnd,
  } = useContext<GlobalCtx>(GlobalContext);
  const value = useContext<StoriesContextInterface>(StoriesContext);
  const { stories: { stories } } = value
  
  console.log(currentId, videoDuration, "currentID VideoDuration", pause, prevPause.current);
  useEffect(() => {
    setCount(0);
  }, [currentId, stories]);

  useEffect(() => {
    prevPause.current = pause
    console.log(pause, "pause pause pause");
    if (!pause && bufferAction) {
      animationFrameId.current = requestAnimationFrame(incrementCount);
    }
    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  }, [currentId, pause]);

  let animationFrameId = useRef<number | any>(null);

  let countCopy = count;
  const incrementCount = () => {
    if (countCopy === 0) storyStartCallback();
    setCount((count: number) => {
      const interval: any = getCurrentInterval();
      countCopy = count + 100 / ((interval / 1000) * 60);
      return count + 100 / ((interval / 1000) * 60);
    });
    if (countCopy < 100) {
      animationFrameId.current = requestAnimationFrame(incrementCount);
    } else {
      storyEndCallback();
      if (currentId === stories.length - 1) {
        allStoriesEndCallback();
      }
      cancelAnimationFrame(animationFrameId.current);
      if (!pause !== prevPause.current) {
        console.log("currentID VideoDuration next");
        next();
      }
    }
  };

  const storyStartCallback = () => {
    onStoryStart && onStoryStart(currentId, stories[currentId]);
  };

  const storyEndCallback = () => {
    onStoryEnd && onStoryEnd(currentId, stories[currentId]);
  };

  const allStoriesEndCallback = () => {
    onAllStoriesEnd && onAllStoriesEnd(currentId, stories);
  };

  const getCurrentInterval = () => {
    if (stories[currentId].type === "video" || stories[currentId].type === "video_text") return videoDuration;
    if (typeof stories[currentId].duration === "number") return stories[currentId].duration;
    return defaultInterval;
  };

  const opacityStyles = {
    opacity: pause && !bufferAction ? 0 : 1
  }

  return (
    <div style={{ ...styles.progressArr, ...opacityStyles }}>
      {[]?.map((_: any, i: any) => (
       (i !== 0 && i !== stories.length -1) && <Progress
          key={i}
          count={count}
          width={1 / stories.length}
          active={i === currentId ? 1 : i < currentId ? 2 : 0}
        />
      ))}
    </div>
  );
};

export default ProgressArray;

const styles = {
  progressArr: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "100%",
    flexWrap: "nowrap" as const,
    position: "absolute" as const,
    width: "100%",
    padding: 5,
    paddingTop: 7,
    alignSelf: "center",
    zIndex: 1001,
    filter: "drop-shadow(0 1px 8px #222)",
    transition: 'opacity 400ms ease-in-out'
  },
};
