import React, { useEffect, useState,useRef } from "react";
import "../../v2/question.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import Play from "../../../images/dark-play.png"
import retry from "../../../images/retry.png"
import { MdOutlineVolumeUp ,MdOutlineVolumeOff} from 'react-icons/md';
import { BsChevronCompactRight,BsChevronCompactLeft} from 'react-icons/bs';
import { BsFillCameraVideoOffFill} from 'react-icons/bs';
import { useParams } from "react-router-dom";
import Logo from "../../../images/walkthru_image.png"
import imageCompression from "browser-image-compression";
import Webcam from "react-webcam";
import { fileUpload } from "../../../api/fileUpload";
import screenView from "../../../api/screenview";
import skip from '../../../images/skip.png'
import { stopwatch } from "../../StopWatch";

const getAlphabeticLetter =(index:number) =>{
  return String.fromCharCode(65 + index);
}

const getRomanNumeral = (index:number) => {
  const romanNumerals = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX"]; 
  if (index >= 0 && index < romanNumerals.length) {
    return romanNumerals[index];
  }
  return getAlphabeticLetter(index);
}

const MultiSelectLayout = ({ data,updatedButton,handleUpdateSlide}: any) => {
  return (
    <div className="form-content">
   <div className="option-layout">
    {console.log(updatedButton,"updatedButtonupdatedButton")
    }
    {  data?.section?.options.map((opt:any, i:number) => {
      
      const alphabeticLetter = getRomanNumeral(i);
          return (
            <button
              key={i}
              className={`option-button ${updatedButton.index === i && updatedButton.class ? 'updated-button' : ''}`}
              onClick={() =>{
                      handleUpdateSlide("multi",opt,data._id,i)
            }         
            }
            >
              <div className="option-text">     
                <span className="alpha-style">{alphabeticLetter}</span>{opt}</div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

const ButtonLayout = ({ data,updatedButton,handleUpdateSlide}: any) => {
  const openLinkInBrowser = () => {
    const link = data?.section?.btnLink.includes("https://")?`${data?.section?.btnLink}/linking`: `https://${data?.section?.btnLink}/linking`
    const isMobileBrowser = /Mobi|Android|iPhone|iPad|iPod|Windows Phone|Tablet/i.test(
      navigator.userAgent
    );
    const isAndroid = /android/i.test(navigator.userAgent);
    const isIos = /iPad|iPhone|iPod/i.test(navigator.userAgent);

    if (link && isMobileBrowser && isAndroid) {
      window.open(link.replace("/linking",""),'_blank')
      // window.location.href=link;
      handleUpdateSlide("button","clicked",data._id)
    }else if(link && isMobileBrowser && isIos){
      // window.open(link,'_blank')
      window.open(link.replace("/linking",""),'_blank')

      // window.location.href=link;
    handleUpdateSlide("button","clicked",data._id)
    }
    else{
    handleUpdateSlide("button","clicked",data._id)
    window.open(link.replace("/linking",""),'_blank')
    }
  };
  
  return (
      <div className="text-layout">
  <div  onClick={() => { openLinkInBrowser()}} className={`button-style ${updatedButton.class ? 'updated-button' : ''}`}>
    {data?.section?.btnLabel}</div>
      </div>
  );
};

const TextLayout = ({ data,updatedButton,handleUpdateSlide,setResponse, textField, setTextField}: any) => {
  return (
      <form className="text-layout" onSubmit={(e) =>{
        e.preventDefault()
        handleUpdateSlide("text")
      } }>    
     <input type="text" value={textField} required placeholder="Type your answer here" onChange={(e)=> {
                setResponse((prevState:any) => ({...prevState,answer:e.target.value,questionId:data._id}));
                setTextField(e.target.value);
     }} size={100} className="text-style"/>
     <button type="submit" className={`button-style ${updatedButton.class ? 'updated-button' : ''}`}>Submit</button>
      </form>
  );
};

const ImageLayout = ({ data,updatedButton,setResponse,setUpdatedButton,setOpenCamera,cameraIsOn,startCamera}: any) => {
  return (
 <button type="submit" className={`button-style ${updatedButton.class ? 'updated-button' : ''}`} onClick={() =>{
  if(cameraIsOn){
    startCamera()
  }else{
    setResponse((prevState:any) => ({...prevState,questionId:data?._id}))
                   setUpdatedButton({index:"",class:true})
                   setTimeout(() => {
                   setUpdatedButton({index:"",class:false})
                     setOpenCamera(true)            
                  },600)         
  }
 }}>Capture Image</button> 
  );
};

const FeedbackStar = ({ data,updatedButton,handleUpdateSlide}: any) => {
  const startGroup =[
    {starId:"rating-5",star:5},
    {starId:"rating-4",star:4},
    {starId:"rating-3",star:3},
    {starId:"rating-2",star:2},
    {starId:"rating-1",star:1},
  ]
  return (
          <div className="star-layout">  
          <h3 style={{color:"#fff"}}>RATE US</h3>
<div className="feedback">
  <div className="rating">
{startGroup.map((str,index) => {
  return(
< >
  <input type="radio" key={index}  name="rating" id={str.starId} />
    <label key={index} htmlFor={str.starId} onClick={() =>{
        handleUpdateSlide("star",str.star,data._id)
      }
    } className={`${updatedButton.class ? 'updated-button' : ''}`}></label>
    </> )
})  }
  </div>
</div>
</div>
  );
};

const FeedbackScale = ({ data,handleUpdateSlide}: any) => {
  const scaleGroup = [
   { scale:1,class:"btn-scale btn-scale-desc-1"},
   { scale:2,class:"btn-scale btn-scale-desc-2"},
   { scale:3,class:"btn-scale btn-scale-desc-3"},
   { scale:4,class:"btn-scale btn-scale-desc-4"},
   { scale:5,class:"btn-scale btn-scale-desc-5"},
   { scale:6,class:"btn-scale btn-scale-desc-6"},
   { scale:7,class:"btn-scale btn-scale-desc-7"},
   { scale:8,class:"btn-scale btn-scale-desc-8"},
   { scale:9,class:"btn-scale btn-scale-desc-9"},
   { scale:10,class:"btn-scale btn-scale-desc-10"},
  ]

  return (
<div className="scale-layout">
<h3 style={{color:"#fff"}}>RATE US</h3>
<div className="scale-btn">
        {scaleGroup.map((scale,index) =>{
       return( <button key={index} className={scale.class} onClick={() =>{
handleUpdateSlide("scale",scale.scale,data._id)
}
}>{scale.scale}</button>)})}
        </div>
      </div>
  );
};

let questionsIndex:any = []
const QuestionComponent = ({questionData, next, responseIDRef , isPlay, videoRef, togglePlay}:any) => {
  console.log(responseIDRef, "videoRef videoRef");  
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dummy, setDummy] = useState(0)
  const [updatedButton, setUpdatedButton] = useState({index:"",class:false});
  const [isMuted, setIsMuted] = useState(false);
  const cameraRef = useRef<HTMLVideoElement | null>(null);
  const webcamRef = useRef<Webcam | null>(null);
  const [questions,setQuestions] = useState<any>()
  const [customer,setCustomer] = useState<any>()
  const [response,setResponse] = useState({responseId:"",answer:"",questionId:""})
  const [loader,setLoader] = useState(false)
  const [openCamera,setOpenCamera] = useState(false)
  const [stream, setStream] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [disable,setDisable] = useState(false)
  const [uploading,setUploading] = useState(false)
  const [textField,setTextField] = useState("")
  let uniqueID = [...new Set(questionsIndex)];
  const [cameraIsOn, setCameraIsOn] = useState(false);
  const [custLogo,setCustLogo] = useState(null)
  const [deviceHeight, setDeviceHeight] = useState('');
  let progressFill =  100 /((questions && questions.length)/(currentSlide)) ;

  const showLeftNav =questions && questions.some((ques:any) => ques._id === uniqueID[currentSlide - 1]);
  const showRightNav =questions && questions.some((ques:any) => ques._id === uniqueID[currentSlide]);
  let { id }: any = useParams();
  

  useEffect(() => {
    AOS.refresh();
    setTextField('');
  }, [currentSlide]);

  useEffect(() => {
    AOS.init();
    return () => AOS.refresh();
  }, []);

  useEffect(() => {
    console.log("useEffect comming comming");
    setDummy(dummy + 1)
  },[isPlay, responseIDRef])

  useEffect(() => {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const mobileBreakpoint = 768; 
    const tabletBreakpoint = 1024;
    const userAgent = navigator.userAgent.toLowerCase();

    if (screenWidth < mobileBreakpoint && userAgent.indexOf('android') !== -1) {
      setDeviceHeight('93%');
    } else if (screenWidth < mobileBreakpoint && userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
      setDeviceHeight('89.7%');
    } else if (screenWidth < tabletBreakpoint) {
      setDeviceHeight('98%');
    } else {
      setDeviceHeight('100%');
    }
  }, []);

  useEffect(()=> {
    stopwatch.start();
  },[])

  useEffect(() =>{    
    setQuestions([questionData])
  },[])

const compressImage = async (file: any): Promise<Blob> => {
  const options = {
    maxSizeMB: 4.5, 
    maxWidthOrHeight: 800,
    useWebWorker: true,
  };
  try {
    const compressedBlob = await imageCompression(file, options);
    return compressedBlob;
  } catch (error) {
    return file;
  }
};

const handleUpload = async() =>{
  setDisable(true)
  setUploading(true)
  const formData = new FormData();
  formData.append("file", imageSrc);
  fileUpload(formData).then(({ data }: any) => {
  setUploading(false)
   handleUpdateSlide("image",data?.url,"","")
  }).catch((err:any) => console.log(err)
  );
}

const startCamera = async () => {
  const userAgent = window.navigator.userAgent;

  if (userAgent.includes('Firefox')) {
    try {
      const facingMode =  'environment';
      const userMediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode },
        audio:false,
      });
      setStream(true);
      setOpenCamera(false)
      if(cameraRef.current) {
        cameraRef.current.srcObject = userMediaStream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  } else if (userAgent.includes('Chrome')) {
    setStream(true);
    setOpenCamera(false)
  } else {
    setStream(true);
      setOpenCamera(false)
  }
};

  const capture = React.useCallback(async() => {
    if(webcamRef.current){
      const image = webcamRef.current.getScreenshot();
      console.log(image,"image");
   if(image){
     const byteCharacters = atob(image.split(',')[1]);
     const byteNumbers = new Array(byteCharacters.length);
     for (let i = 0; i < byteCharacters.length; i++) {
       byteNumbers[i] = byteCharacters.charCodeAt(i);
     }
     const byteArray = new Uint8Array(byteNumbers);
     const blob = new Blob([byteArray], { type: 'image/png' });
     if (blob) {
      const compressedBlob =  await compressImage(blob);
      setImageSrc(compressedBlob);
    }
   }
    }
  }, [webcamRef, setImageSrc]);

  const videoConstraints = {
    facingMode: "environment"
  };

  const handleUpdateSlide =async (type:string,option:string,quesID:string,i:string) =>{
    isPlay.current = true
    const {responseId,answer,questionId} =response
      setUpdatedButton({index:i,class:true})
      setTimeout(() => {
      setUpdatedButton({index:"",class:false})
      if (questions.length > currentSlide +1) {
        questionsIndex.push(quesID || questionId)
      setCurrentSlide((preState: any) => preState + 1)
      }else{
        setLoader(true)
      }
 
    }, 600) 
    const screen = stopwatch.getElapsedTime();
const body = {
  section:{
    videoClip:quesID || questionId,
    answer:option || answer,
    duration: screen
  },
  collectionId:id
}
stopwatch.reset();
if(responseIDRef.current){
  await screenView<any>("PUT", `response/${responseIDRef?.current}`,body).then((data:any) =>{
  setResponse((prevState) => ({...prevState,answer:"",questionId:""}))
   setDisable(false)
   next()
    if(type === "image"){
       setImageSrc(null);setStream(false)
    }
  })
}else{
  await screenView<any>("POST", `response`,{...body, agent: navigator?.userAgent}).then((data:any) =>{
    if(type === "image"){
      setImageSrc(null);setStream(false)
   }
   next();
    setDisable(false)
    responseIDRef.current = data?.responseObj?.data?._id
    setResponse((prevState) => ({...prevState,responseId:data?.responseObj?.data?._id,answer:"",questionId:""}))
  }).catch((err:any) => {
    console.log(err);
    
  })
}
  }
  console.log(questionData, 'questions questions');
  
  return (
    <div className="bg-color">
      <div className="player-container">

     {openCamera && !cameraIsOn ? <div className="center-container " >
            <div className="center-container card-size" style={{flexDirection:"column",gap:20}}>
            <h1 style={{fontSize:16,
            lineHeight: "120%",
          fontWeight: 700,
textAlign: "center",
color: "rgb(17, 17, 17)"}}>
            To capture image, your browser will need to request access to your camera.
            </h1>
            <div className="center-container permission-card">
<BsFillCameraVideoOffFill fontSize={50}/>
            <p style={{color: "#808080",
fontSize: 14,
fontWeight: 500,
}}>Camera is not active</p>
            </div>
            <button className="permission-btn" onClick={startCamera}>
            Request permissions
            </button>
            </div>
            </div>:  
            !openCamera && stream ?

<div className="center-container" onClick={capture} style={{position:"relative"}}>
{!imageSrc &&  <div className={`capture-btn`} >
  <div className="circle"></div>
  <div className="ring"></div>
  </div>}
  <canvas ref={canvasRef} style={{ display: 'none' }} />
{imageSrc?
<>
{uploading && 
<div className="uploading-container">
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </div>
        </div>
        }
<div className="confirm-capture">Do you want to save?</div>
<div className="action-layout">
  <div className="confirm-btn" onClick={handleUpload} style={{pointerEvents:disable?"none":"auto"}}>Yes</div>
  <div className="cancel-btn" onClick={()=>{setImageSrc(null);setStream(false);
  setUploading(false);
  setDisable(false)
}}>No</div>
</div>
<img src={URL.createObjectURL(imageSrc)} alt="captured-image" className="videoContent"/></>:<Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        videoConstraints={videoConstraints}
        // height={800}
        // width={510}
        className="videoContent"
      />}
  </div>
            :
           <div className="video-container" style={{height:deviceHeight}} key={questionData?.id}   data-aos="zoom-in-up"
     data-aos-once="true" >
      <div className="progress-line">
        <span className="progress-fill" style={{width:`${progressFill}%`}}></span>
      </div>
            {custLogo && <div className="cust-logo">
            <img src={custLogo} alt="customer-logo" width={50}/>
            </div>}
            <div className="skip-logo" onClick={() => {handleUpdateSlide("","skiped",questionData?._id,"")}}>
            <img src={skip} alt="skip" width={30}/>
            </div>
            {!isPlay.current && <div className="play-btn" onClick={togglePlay} data-aos="zoom-out" data-aos-duration="700">
          <button style={{backgroundColor:"transparent", fontSize: '15px', color: "#fff"}} > <img src={retry} height={40} width={40}/> Replay</button>
          </div>}
        {currentSlide !== 0 && showLeftNav && <div className="left-nav"
         onClick={()=>{
            setCurrentSlide((preState: any) => preState - 1)
         }}><BsChevronCompactLeft fontSize={50} color="#00000073"/></div>}
         { questionData.length > currentSlide +1 && showRightNav && <div className="right-nav" onClick={()=>{
            setCurrentSlide((preState: any) => preState + 1)
         }}><BsChevronCompactRight fontSize={50} color="#00000073"/></div>}
          <div className="content-layer" onClick={togglePlay}/>
       {questionData &&<>
         {questionData.answerType === "multi" &&  
          <div className="intract-container"  data-aos="zoom-in-up" data-aos-once="true">
         <MultiSelectLayout  updatedButton={updatedButton}  data={questionData}  handleUpdateSlide={handleUpdateSlide}/>
         </div>}
         {questionData.answerType === "button" &&  
          <div className="capture-container"  data-aos="zoom-in-up" data-aos-once="true">
         
         <ButtonLayout  updatedButton={updatedButton}  data={questionData}  handleUpdateSlide={handleUpdateSlide}/>
         </div>}
         {questionData.answerType === "text" &&
          <div className="text-container" key={currentSlide}  data-aos="zoom-in-up" data-aos-once="true">
         
         <TextLayout  updatedButton={updatedButton}  data={questionData}  handleUpdateSlide={handleUpdateSlide} setResponse={setResponse} textField={textField} setTextField={setTextField}/>
         </div>
         }
            {questionData.answerType === "image" &&  
          <div className="capture-container"  data-aos="zoom-in-up" data-aos-once="true">
            
            <ImageLayout updatedButton={updatedButton}  data={questionData} setResponse={setResponse} cameraIsOn={cameraIsOn} startCamera={startCamera} setUpdatedButton={setUpdatedButton} setOpenCamera={setOpenCamera}/>
         </div>
        }
          {questionData.answerType === "scale" &&
          <div className="text-container"  data-aos="zoom-in-up" data-aos-once="true">
         
         <FeedbackScale    data={questionData}  handleUpdateSlide={handleUpdateSlide} />
         </div>
         }
           {questionData.answerType === "star" &&
          <div className="text-container"  data-aos="zoom-in-up" data-aos-once="true">
         <FeedbackStar  updatedButton={updatedButton}  data={questionData}  handleUpdateSlide={handleUpdateSlide}/>
         </div>
         }
         </>}
        </div>
        } 
      </div>
    </div>
  );
};

export default QuestionComponent;
