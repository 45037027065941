import * as React from "react";
import Spinner from "../components/Spinner";
import { Renderer, Tester } from "../../../interfaces";
import WithHeader from "./wrappers/withHeader";
import WithSeeMore from "./wrappers/withSeeMore";
import { Subscription } from "../../subscription";

const notFound =
  "https://icloud-image-uploader.s3.amazonaws.com/report_images/6342464.jpg";

export const RendererComponent: Renderer = ({
  story,
  action,
  isPaused,
  config,
}) => {
  const [loaded, setLoaded] = React.useState(false);
  const { width, height, loader, storyStyles } = config;
  const [styleClass, setStyleClass] = React.useState("");

  let computedStyles = {
    ...styles.storyContent,
    ...(storyStyles || {}),
  };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      if (loaded && story.text) {
        setTimeout(() => {
          setStyleClass("content-header");
        }, 200);
      }
      return () =>{
        setStyleClass("");  
      }
    }, [loaded, story]);


  const imageLoaded = () => {
    setLoaded(true);
    action("play",true);
  };

  return (
    <WithHeader {...{ story, globalHeader: config.header || (() => {}) }}>
       {story.text && loaded && styleClass &&(
        <div className={`${styleClass}`}>{story.text}</div>
      )} 
      <WithSeeMore {...{ story, action }}>
        <div>
          {/* {!story.url?.localeCompare(notFound) ? (
            <Subscription onload={imageLoaded} />
          ) : ( */}
            {/* // renderImage(story) */}
            <img
              className={story?.className}
              src={story.url}
              alt=""
              onLoad={imageLoaded}
            />
           {/* )}  */}
          {/*  */}
          {!loaded && (
            <div
              style={{
                width: width,
                height: height,
                position: "absolute",
                left: 0,
                top: 0,
                background: "rgba(0, 0, 0, 0.9)",
                zIndex: 9,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#ccc",
              }}
            >
              {loader || <Spinner />}
            </div>
          )}
        </div>
      </WithSeeMore>
    </WithHeader>
  );
};

const styles = {
  story: {
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  storyContent: {
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
  },
};

export const tester: Tester = (story) => {
  return {
    condition: !story.content && (!story.type || story.type === "image" ||story.type ==="image_text"),
    priority: 2,
  };
};

export default {
  renderer: RendererComponent,
  tester,
};
