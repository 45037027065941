import React, { ComponentType, useState, useContext, useEffect } from "react";
import {
  Action,
  SeeMoreProps,
  Story,
  StoriesContext as StoriesContextInterface,
} from "../../../../interfaces";
import SeeMore from "../../components/SeeMore";
import StoriesContext from "../../context/Stories";
import QuestionComponent from "../../components/Question";
// import "../../../v2/questions.scss";

const WithSeeMore: React.FC<
  React.PropsWithChildren<{
    story: Story;
    action: Action;
    customCollapsed?: SeeMoreProps["customCollapsed"];
    isPlay?: any,
    videoRef?: any,
    togglePlay?: Function,
  }>
> = ({ story, action, customCollapsed, children, isPlay, videoRef, togglePlay }) => {
  const [showMore, setShowMore] = useState(false);
  const data = useContext<StoriesContextInterface>(StoriesContext);
  const { setToggle } = data;
  const { next } = story;
  const [dummy, setDummy] = useState(0)

  console.log(next, "datadatadatadatadatadata");
  
  const toggleMore = (show: boolean) => {
    action(show ? "pause" : "play", true);
    setShowMore(show);
  };
  const state = (show: boolean) => {
    setToggle(show);
  };

  const questionParams = {
    questionData:story?.questionData,
    responseIDRef:story?.responseId,
    next:()=>{next && next()},
    isPlay:isPlay,
    videoRef:videoRef,
    togglePlay:togglePlay,
  }

  useEffect(() => {
    console.log("videoRef videoRef with");
    setDummy(dummy + 1)
    console.log(isPlay, "isPlay isPlay isPlay", togglePlay);
  },[story, isPlay])


  

  const CollapsedComponent = SeeMore;
  return (
    <>
      <div className="image-div">{children}</div>
      {story.seeMore && (
        <div
          style={{
            position: "absolute",
            margin: "auto",
            bottom: showMore ? "unset" : story?.isRestricted?"50px":0,
            zIndex: 9999,
            width: "100%",
            height: showMore ? "100%" : "auto",
            top: showMore ? "0" : "unset",
          }}
        >
          <CollapsedComponent
            action={action}
            toggleMore={toggleMore}
            showContent={showMore}
            state={state}
            seeMoreContent={story.seeMore}
            customCollapsed={customCollapsed || story.seeMoreCollapsed}
          />
        </div>
      )}
      {
        (story?.questionData?.type === "question") && (
          <div
          style={{
            position: "absolute",
            margin: "auto",
            zIndex: 9999,
            width: "100%",
            height: "100%",
            top: "0",
          }}
          >
          <QuestionComponent 
            {...questionParams}
          />
          </div>
        )
      }
    </>
  );
};

export default WithSeeMore;
