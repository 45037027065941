import React, { useEffect, useState } from "react";
import success from "../../images/success_tick.png";
import salesLogo from "../../images/new-logo.png";
import screenView from "../../api/screenview";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpinnerLoader } from "../../common/Global.Style";

const Join = () => {
const screenWidth = window.innerWidth < 512 ? "100%" : 512;
  const id = window?.location?.href?.split("/")[4]
  const [techData, setTechData] = useState<any>({})
  const [open, setOpen] = useState(false)
  const [company, setCompany] = useState('')
  const [error, setError] = useState(false)
  const [revoke, setRevoke] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [loading,setLoading] = useState(true)
const [formData,setFormData] = useState<any>({
  first_name:"",
  last_name:"",
  email:"",
  password:"",
  contractor_name:""
})


  useEffect(() => {
      if (id) {
        fetchTechDetails(id);
      }
  }, [id])

  const fetchTechDetails = async (id:any) => {
    const data: any = await screenView<any>("GET", `technicians/${id}/details`);
    const comVal = data?.responseObj?.data
    const objKey = comVal ? Object?.keys(comVal) : []
    if(objKey.includes('invitedId')) {
      if(comVal?.invitedId._id === comVal?.contractorId) {
        setError(true)
      }
    } else {
      setRevoke(true)
    }
    setFormData({firstName:data?.responseObj?.data?.firstName,lastName:data?.responseObj?.data?.lastName,email:data?.responseObj?.data?.email,contractor_name:data?.responseObj?.data?.invitedId?.name})
    setTechData(data?.responseObj?.data)
    setCompany(comVal?.invitedId?.name ? comVal?.invitedId?.name : comVal?.invitedId?.email)
    setLoading(false)
  }

  const handleCreate = async(e:any) =>{
e.preventDefault()
const body = {
  firstName: formData.firstName,
  lastName: formData.lastName,
  password: formData.password
}
const data = await screenView<any>("PUT", `technicians/${id}/setup`,body)
if(data?.responseObj) {
    handleOpen();
}
  }

  const fetchTech = async () => {
    const data = await screenView<any>("PUT", `technicians/${id}/join`)
    if(data?.responseObj) {
        handleOpen();
    }
  }
  const handleChange = (event:any) =>{
    const {value,name} = event.target
    setFormData((prevState:any) => ({ ...prevState, [name]: value }));
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const handleOpen = () => {
      setOpen(!open)
  }

  return (
    <div
      style={{
        background: "#f7f5f2",
        width: "100%",
        height: "100vh",
      }}
    >
      <div style={{ width: screenWidth, margin: "0 auto", background: "#fff" }}>
        <div
          style={{
            padding: "0px 10px 0px",
            borderBottomColor: "rgb(238, 238, 238)",
            borderWidth: "0 0 1px",
            borderBottomStyle: "solid",
          }}
        >
          <img src="https://icloud-image-uploader.s3.amazonaws.com/explainer/video_brochure.png" alt="logo" style={{width: "130px", objectFit: 'scale-down'}} />
        </div>
        {
          loading ? 
          <div style={{display:"flex" ,justifyContent:"center",alignItems:"center"}}>
            <SpinnerLoader/>
            </div> :
            <>
            {(error || revoke) && 
        <>
        <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <h4 style={{ marginBottom: 8, padding: '10px 30px', minHeight: '100px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center' }}>
                    {error ? 'This invite is already used.' : 'This invite is revoked.'}
                </h4>
        </div>
        </>}
        {  (!open  && (!error || !revoke) && techData?.invitationStatus === "invited" )&&
        <>
        <div style={{ padding: "0px 20px" }}>
          <p
            style={{
              fontWeight: 400,
              fontSize: "24px",
              color: "rgb(30, 25, 25)",
              padding: "20px 0",
            }}
          >
            Link your account with {company}
          </p>
          <p
            style={{
              fontSize: "22px",
              fontWeight: 500,
              color: "rgb(30, 25, 25)",
              paddingBottom: "20px",
              lineHeight: "normal",
            }}
          >
            Hi <span style={{textTransform: "capitalize"}}>{techData?.firstName ? techData?.firstName : techData?.email}</span>,
          </p>
          <p
            style={{
              fontSize: "16px",
              color: "rgb(30, 25, 25)",
              fontWeight: "normal",
              lineHeight: 1.75,
              paddingBottom: "20px",
            }}
          >
            You are invited to join as a technician under {company}. On confirmation {company} will be able to view the reports and customer details generated from your Air Quality Doctor / Walkthru account(s).
          </p>
        </div>
      {techData?.isEmailVerified?  <div style={{ textAlign: "center" }}>
          <button
            style={{
              fontSize: "18px",
              color: "rgb(247, 245, 242)",
              fontWeight: 400,
              lineHeight: "40px",
              backgroundColor: "#0061fe",
              cursor: "pointer",
              width: "50%",
              margin: "0 auto",
              padding: "0px",
              // '': {
              //     backgroundColor: "#0061fe",
              // }
            }}
        onClick={() => fetchTech()}
          >

            Link Account
          </button>
        </div>: <form autoComplete="off" onSubmit={(e)=>handleCreate(e)}> <div style={{display:"flex",flexDirection:"column",padding:15}} >
          <div style={{display:"flex",gap:15,marginBottom:15}}>
            <div>
<input placeholder="First Name" autoComplete="off" name="firstName" required value={formData?.firstName || ""} onChange={handleChange}/>
            </div>
            <div>
            <input placeholder="Last Name" autoComplete="off" name="lastName" required value={formData?.lastName || ""} onChange={handleChange}/>

            </div>
            
          </div>
          <div style={{display:"flex",gap:15,marginBottom:15}}>
            <div>
<input placeholder="Email" autoComplete="off" value={formData.email || ""} disabled name="email"/>
            </div>
            <div>
            <input placeholder="Contractor Name"  autoComplete="off" value={formData?.contractor_name || ""} disabled name="contractor_name"/>

            </div>
            
          </div>
          <div className="password-input-container">
          <input
          required
          type={showPassword ? 'text' : 'password'}
          value={formData.password || ""}
          onChange={handleChange}
          placeholder="Password"
          autoComplete="off"
          name="password"
        />
        <FontAwesomeIcon
          icon={showPassword ?  faEye:faEyeSlash}
          className="password-icon"
          onClick={togglePasswordVisibility}
          style={{color: "#c0bfbc"}}
        />
          </div>
          <div style={{ textAlign: "center" }}>
          <button
            style={{
              fontSize: "18px",
              color: "rgb(247, 245, 242)",
              fontWeight: 400,
              lineHeight: "40px",
              backgroundColor: "#0061fe",
              cursor: "pointer",
              width: "100%",
              margin: "0 auto",
              padding: "0px",
              // '': {
              //     backgroundColor: "#0061fe",
              // }
            }}
            type="submit"
          >

            Link Account
          </button>
        </div>
        </div></form>}
        <div style={{ padding: "0px 20px" }}>
          <p
            style={{
              fontSize: "16px",
              color: "rgb(30, 25, 25)",
              fontWeight: "normal",
              lineHeight: 1.75,
              padding: "20px 0px",
            }}
          >
            If you don't want to link your account, you can simply close this
            page.
          </p>
        </div>
        </> }
      {  open && 
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <img
                    src={success}
                    alt="success"
                    style={{padding: "20px"}}
                />
                <h4 style={{ marginBottom: 8 }}>
                    Success!
                </h4>
                <p style={{ textAlign: 'center', padding: '10px 20px' }}>Your account is now successfully linked with {company}.</p>
                <p style={{ textAlign: 'center', padding: '10px 5px' }}>If you wish to unlink, please write to us at care@bldghealth.net</p>
            </div>
        </>
        }
            </>
        }
        </div>
    </div>
  );
};

export default Join;
