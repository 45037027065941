import React from "react";
import "./stripe.scss";
// import Lottie from "react-lottie";
// import * as expire from "./expire.json";
import Logo from "../../images/walkthru_image.png";

export const Expired = () => {
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: expire,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };
  // const height = window.innerWidth > 640 ? 500 : 300;
  return (
    <div className="bg-gray">
      <div className="stripe-container">
        <div
          className="center-container"
          style={{ position: "sticky", top: 20, background: "#fff", flex: 0 }}
        >
          <img
            src={Logo}
            alt="logo"
            style={{ width: "150px", objectFit: "scale-down" }}
          />
        </div>
        {/* <Lottie options={defaultOptions} height={height} width={"100%"} /> */}
        <img
          src="https://assets.bldghealth.net/explainer/image/expired.png"
          alt="expire"
          style={{ width: "100%", padding: "4rem 0rem" }}
        />
        <div className="stripe-content">
          <p className="title stripe-failed-color">Expired!</p>
          <p className="content">The record has been timed out.</p>
          <p className="line" />
        </div>
      </div>
    </div>
  );
};
