import React, { useEffect } from "react";
import screenView from "../../api/screenview";
import { useParams, useHistory } from "react-router-dom";

const QrCode = () => {
  let { id }: any = useParams();
  const history = useHistory();

  useEffect(() => {
    getQrData();
  }, []);

  const getQrData = async () => {
   const response = await screenView<any>("GET", `short-url/${id}/validate`);
    const {url} = response?.responseObj
    window.location.assign(url)
    console.log(url.split("/").slice(3).join("/"), "response response");
  };

  return <></>;
};

export default QrCode;
