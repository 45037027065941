import React, { useEffect, useContext, useState } from "react";
import GlobalContext from "../context/Global";
import {
  GlobalCtx,
  StoriesContext as StoriesContextInterface,
} from "../../../interfaces";
import { SeeMoreProps } from "../../../interfaces";
import StoriesContext from "../context/Stories";

export default function SeeMore(props: SeeMoreProps) {
  const SeeMoreContent = props.seeMoreContent;
  const CustomCollapsed = props.customCollapsed;
  const [trans, setTrans] = useState("");
  const [showCon, setShowcon] = useState(true);

  const { keyboardNavigation } = useContext<GlobalCtx>(GlobalContext);
  const data = useContext<StoriesContextInterface>(StoriesContext);
  const { setToggle } = data;

  useEffect(() => {
    const isClient = typeof window !== "undefined" && window.document;
    if (
      isClient &&
      (typeof keyboardNavigation === "boolean" && keyboardNavigation)
    ) {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [keyboardNavigation]);

  useEffect(() => {
    if (props.showContent) {
      setTimeout(() => {
        setTrans("seeMoreStyle");
      }, 200);
    }
  }, [props.showContent]);
  useEffect(() => {
    setTrans("");
  }, [showCon]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      props.toggleMore(true);
      setToggle(true);
    } else if (e.key === "ArrowDown" || e.key === "Escape") {
      e.preventDefault();
      setShowcon(!showCon);
      setToggle(false);
      setTimeout(() => {
        props.toggleMore(false);
      }, 600);
    }
  };

  return props.showContent ? (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <div className={`seeMoreExpanded ${trans}`}>
        <SeeMoreContent
          close={() => {
            setShowcon(!showCon);
            setToggle(false);
            setTimeout(() => {
              props.toggleMore(false);
            }, 600);
          }}
        />
      </div>
    </div>
  ) : CustomCollapsed ? (
    <CustomCollapsed
      action={props.action}
      toggleMore={props.toggleMore}
      state={props.state}
    />
  ) : (
    <div
      style={styles.seeMore}
      onClick={(e) => {
        setToggle(true);
        e.preventDefault();
        e.stopPropagation();
        props.toggleMore(true);
      }}
    >
      <span style={styles.seeMoreIcon}>⌃</span>
      <span style={styles.seeMoreText}>See more</span>
    </div>
  );
}

const styles: any = {
  seeMore: {
    height: "10vh",
    background: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    bottom: 0,
  },

  seeMoreText: {
    color: "white",
    textAlign: "center",
    letterSpacing: "0.1em",
    marginBottom: "2.2vh",
    textTransform: "capitalize",
    opacity: "1",
    fontSize: "0.8em",
    transition: "opacity 300ms ease-in-out",
  },
  seeMoreIcon: {
    color: "white",
    textAlign: "center",
    letterSpacing: "0.2em",
    marginBottom: "0.4vh",
    opacity: "1",
    filter: "drop-shadow(0 0 5px black)",
    textTransform: "capitalize",
    transition: "opacity 300ms ease-in-out",
  },
  seeMoreClose: {
    position: "absolute",
    filter: "drop-shadow(0 3px 2px #ccc)",
    right: "0.5rem",
    top: "0.5rem",
    fontSize: "1.5rem",
    opacity: "0.7",
    padding: "1rem",
  },
};
