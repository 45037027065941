import * as React from "react";
import Spinner from "../components/Spinner";
import { Renderer, Tester } from "../../../interfaces";
import WithHeader from "./wrappers/withHeader";
import WithSeeMore from "./wrappers/withSeeMore";
import Logo from "../../../images/new-report.png";
import ReactHlsPlayer from 'react-hls-player';
import Hls from 'hls.js';
// import JWPlayer from '@jwplayer/jwplayer-react';
import ReactJWPlayer from 'react-jw-player';
import videojs from 'video.js';
import loader from '../../../images/.gif'
// import 'videojs-reload-source-on-error'; // Import the plugin

// function process(playlist) {
//   return playlist;
// }

// class pLoader extends Hls.DefaultConfig.loader {
//   constructor(config: any) {
//     super(config);
//     var load = this.load.bind(this);
//     this.load = function (context, config, callbacks) {
//       if (context.type == 'manifest') {
//         var onSuccess = callbacks.onSuccess;
//         callbacks.onSuccess = function (response, stats, context) {
//           const networkDetails = getNetworkDetails();
//           response.data = process(response.data);
//           onSuccess(response, stats, context, networkDetails);
//         };
//       }
//       load(context, config, callbacks);
//     };
//   }
// }


export const renderer: Renderer = ({
  story,
  action,
  isPaused,
  config,
  messageHandler,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loaded, setLoaded] = React.useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [muted, setMuted] = React.useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [styleClass, setStyleClass] = React.useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const timer = React.useRef<any>();
  const { width, height, loader, storyStyles } = config;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isPlay, setIsPlay] = React.useState(true);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const playButton = React.useRef<any>(true);


  let computedStyles = {
    // ...styles.storyContent,
    ...(storyStyles || {}),
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let vid = React.useRef<HTMLVideoElement>(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (vid.current) {
      console.log(isPaused, "isPaused its working");
      
      if (isPaused) {
        console.log("isPaused its working");
        action("pause", true);
        vid.current.pause();
      } else {
        action("play", true);
        vid.current.play().catch(() => {});
      }
    }
  }, [isPaused]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (timer.current) {
      setTimeout(() => {
        setStyleClass("content-header");
      }, 200);
    }
    return () => {
      setStyleClass("");
    };
  }, [timer.current]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        const scrollY = window.scrollY;
        window.scrollTo(0, 0);
      // if(story?.streamVideo) {
      //   console.log(story?.streamVideo, "streamVideo streamVideo", story?.url)
      //   if(vid.current) {
      //     const videoElement = vid.current;
      //     const hls = new Hls();
      //     // setLoaded(false);
      //     // action("pause", true);
      //     if (Hls.isSupported()) {
      //       hls.loadSource(`${story?.url}`);
      //       hls.attachMedia(videoElement);

      //       hls.on(Hls.Events.MANIFEST_PARSED, (e, data: any) => {
      //         const { networkDetails } = data.stats;
      //         console.log('Network details:', networkDetails);
      //         // playButton.current= playButton.current ==
      //         videoElement.play();
      //       });

      //       videoElement.addEventListener('loadeddata', () => {
      //         action("pause", true);
      //         timer.current = videoElement.duration;
      //         messageHandler("UPDATE_VIDEO_DURATION", {
      //           duration: videoElement.duration,
      //         });
      //       })

      //       videoElement.addEventListener('ended', onVideoEnd);

      //       videoElement.addEventListener('waiting', () => {
      //         action("pause", true)
      //         console.log('Video is waiting (buffering)...');
      //       });
            
      //       return () => {
      //         videoElement.removeEventListener('ended', onVideoEnd);
      //       };
      //     }
      //   }
      // }
  }, [story]);

  const onVideoEnd = () => {
    action("play", true);
    if(!playButton.current) {
      playButton.current =true
    }
    if(story.streamVideo && story.videoByteType !== "question") {
      const {next} = story;
      next && next();
    } else {
      playButton.current=false
    }
    // setLoaded(false)
  }

  const onWaiting = () => {
    action("pause", true);
  };

  const onPlaying = () => {
    action("play", true);
  };
console.log(story,"storystorystorystory");

  const videoLoaded = () => {
    if (vid.current) {
      playButton.current=true
      timer.current = vid.current.duration;
      messageHandler("UPDATE_VIDEO_DURATION", {
        duration: vid.current.duration,
      });
      setLoaded(true);
      action("pause", true);
      vid.current.addEventListener('canplay', () => {
        if(vid.current) {
        vid.current
          .play()
          .then(() => {
            action("play", true);
            console.log('play 1');
          })
          .catch(() => {
            setMuted(true);
            vid.current &&
              vid.current.play().then(() => {
                action("play", true);
              }).finally(() => {
                action("play", true);
              });
          });
        }
      })
    }
  };

  const togglePlay = () => {
    if(story.streamVideo) {
      const videoElement = document.getElementById('hls-video') as HTMLVideoElement;
      if(videoElement) {
        videoElement.play()
        playButton.current = true
      }
    } else {
      if (vid.current) {
      playButton.current = true
      setIsPlay(false)
      vid.current
        .play()
        .then(() => {
          action("play", true);
          playButton.current = true
          setIsPlay(false)
        })
        .catch(() => {
          setMuted(true);
          vid.current &&
            vid.current.play().finally(() => {
              action("play", true);
              playButton.current = true
              setIsPlay(false);
            });
        });
      vid.current.addEventListener("ended", updateButton);
      return () => {
        vid.current && vid.current.removeEventListener("ended", updateButton);
      };
    }
    }
  };

//eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(()=> {
    if(story.streamVideo) {
      setLoaded(false)
    }
    playButton.current = true
    const videoElement = document.getElementById('hls-video');
    if(videoElement) {
      videoElement.addEventListener('waiting', function () {
        setLoaded(false)
      });

      videoElement.addEventListener('canplay', function () {
        console.log('Video can play without buffering.');
        setLoaded(true)
      });

    }

    // var player = videojs('hls-video', {
    //   loader: {
    //     text: 'Loading...'
    //   }
    // });
    // player.src(story?.url);
    // player.reloadSourceOnError();
  },[story])

//eslint-disable-next-line react-hooks/rules-of-hooks
  // React.useEffect(() => {
  //   function fireOnVideoStart() {
  //     // Do some stuff when the video starts/resumes playing
  //     vid.current?.play()
  //   }

  //   if(vid.current) {
  //     vid.current.addEventListener('play', fireOnVideoStart);
  //     return vid.current.removeEventListener('play', fireOnVideoStart);
  //   }


  // }, [vid]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
    // React.useEffect(() => {
    //   let player:any;
    //   if(story?.streamVideo) {
    //     if(vid.current) {
    //       player = videojs(vid.current, {
    //         controls: false,
    //         autoplay: true,
    //         sources: [
    //           {
    //             src: story?.url,
    //             type: 'application/vnd.apple.mpegurl'
    //           }
    //         ]
    //       })
    //     }
    //   }
    //   return () => {
    //   if (player) {
    //     player.dispose();
    //   }
    // };
    // },[story])

  const updateButton = () => {
    console.log("cudsfkndflnoisdnfsadfsdfn");
    if(playButton.current) {
      playButton.current = false
    }
  }

  return (
    <>
      {story.text && loaded && styleClass && (
        <div className={`${styleClass}`} style={{zIndex:'9999'}}>{story.text}</div>
      )}
      {story?.logo && <div className="video-logo">
        <img src={story?.logo} alt="" />
      </div>}
      {story?.isRestricted && 
       	<div style={{
            position: "absolute",
            bottom:-40,
            textAlign:"center",
            left: "50%",
            backgroundColor:"#fff",
            transform: "translate(-50%, -50%)",
            zIndex: "9999",
            padding:"10px 20px",
            width:"100%",
            fontSize:"20px",
            fontWeight:600,
            color: "#9155FD",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            gap:10
          }}
        >
          <div>
         Powered By</div> <img src={Logo} alt="logo" width={150}/>
           </div>
      }
      <WithHeader {...{ story, globalHeader: config.header || (() => {}) }}>
        <WithSeeMore {...{ story, action, isPlay: playButton, videoRef: vid, togglePlay}}>
          <div style={styles.videoContainer} data-vjs-player>
          {story?.streamVideo ? 
            <video
              id="hls-video"
              width="100%"
              height="100%"
              className="storyContent"
              autoPlay
              playsInline
              preload="auto"
              webkit-playsinline="true"
              src={`${story.url}`}
              data-setup='{}'
              onEnded={onVideoEnd}
            />
            :
            <video
              ref={vid}
              // key={story?.id}     // autoplay mute issue
              className="storyContent"
              src={story.url}
              controls={false}
              onLoadedData={videoLoaded}
              onWaiting={onWaiting}
              onPlaying={onPlaying}
              muted={muted}
              autoPlay
              playsInline
              webkit-playsinline="true"
              onEnded={() => {setIsPlay(false); playButton.current=false}}
            />
          }
            {story?.overlayImg && <div style={{position: "absolute",top: '0', left:'0', width: '100%', height: '100%',background: '#000',zIndex:'999'}}>
              <img 
              src={story?.overlayImg}
              alt=""
              style={{width: '100%', height: '100%', objectFit: 'scale-down',objectPosition: 'center'}}
            />
            </div>}
            {story?.showBtn && 
              <a href={story?.btnLink} target="_blank" style={{
                  top: '85%',
                  left:'50%',
                  transform: 'translate(-50%,-50%)',
                  position: 'absolute',
                  zIndex: '9999',
                  color: "rgb(255, 255, 255)",
                  background: "rgba(0, 0, 0, 0.45)",
                  padding: "18px",
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                  borderRadius: "100px",
                  transition: "background-color 0.4s ease 0s",
                  width: "90%",
                  fontSize: "1.25rem",
                }}
                className="button-style"
              >{story?.btnLabel}</a>
            }
            {!loaded &&(
              <div
                style={{
                  width: width,
                  height: height,
                  position: "absolute",
                  left: 0,
                  top: 0,
                  background: "rgba(0, 0, 0, 0.9)",
                  zIndex: 9,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ccc",
                }}
              >
                {loader || <Spinner />}
              </div>
            )}
          </div>
        </WithSeeMore>
      </WithHeader>
    </>
  );
};

const styles = {
  // storyContent: {
  //   width: "auto",
  //   maxWidth: "100%",
  //   maxHeight: "100%",
  //   margin: "auto",
  // },
  videoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const tester: Tester = (story) => {
  return {
    condition: story.type === "video" || story.type === "video_text",
    priority: 2,
  };
};

export default {
  renderer,
  tester,
};
