import React from "react";
import "./stripe.scss";
import Lottie from "react-lottie";
import * as failed from "./failed.json";

export const Failed = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: failed,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const height = window.innerWidth > 640 ? 500 : 300;
  return (
    <div className="bg-gray">
      <div className="stripe-container">
        <div className="stripe-header stripe-failed">
          <h4>Payment Confirmation</h4>
        </div>
        <Lottie options={defaultOptions} height={height} width={"100%"} />
        <div className="stripe-content">
          <p className="title stripe-failed-color">Payment Failed!</p>
          <p className="content">We couldn't get your payment.</p>
          <p className="line" />
        </div>
      </div>
    </div>
  );
};
