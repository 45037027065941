import { SET_TOKEN, SET_USER, CLEAR_USER } from "../actions/actionTypes";

export const userReducer = (state = {}, action: any) => {
	switch (action.type) {
		case SET_USER:
			return { ...state, ...action.payload };
		case SET_TOKEN:
			return { ...state, token: action.payload };
		case CLEAR_USER:
			return {};
		default:
			return state;
	}
};
