import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import GlobalContext from "../context/Global";
import StoriesContext from "../context/Stories";
import ProgressContext from "../context/Progress";
import Story from "./Story";
import ProgressArray from "./ProgressArray";
import {
  GlobalCtx,
  StoriesContext as StoriesContextInterface,
} from "../../../interfaces";
import useIsMounted from "../util/use-is-mounted";
import { stopwatch } from "../../StopWatch";
import screenView from "../../../api/screenview";
import { useParams } from 'react-router-dom';
import Hls from 'hls.js';

let previousIndex:any=null;
const Container = () => {
  const [currentId, setCurrentId] = useState<number>(0);
  const [pause, setPause] = useState<boolean>(false);
  const [bufferAction, setBufferAction] = useState<boolean>(true);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const isMounted = useIsMounted();

  let mousedownId = useRef<any>();

  const {
    width,
    height,
    loop,
    currentIndex,
    isPaused,
    keyboardNavigation,
    preventDefault,
    storyContainerStyles = {},
  } = useContext<GlobalCtx>(GlobalContext);

  const data = useContext<StoriesContextInterface>(StoriesContext);
  const {
    stories: { stories },
    toggle,
  } = data;

  const screenViewIDRef = useRef();
  const responseIDRef = useRef<any>();
  const currentIDRef = useRef<any>();
  const route: any = useParams();
  currentIDRef.current = currentId; 

  const imageLoaded = (url: string) => {
    const image = document.createElement("img");
    image.src = url;
    image.alt = url;
  };

  const videoLoaded = (url: string, streamVideo: any, story: any) => {
    const video = document.createElement("video");
    if(streamVideo) {
      // video.canPlayType("application/vnd.apple.mpegurl")
      // console.log(url, "url url urlurl url");
      // const hls = new Hls();
      // hls.loadSource(url);
      // hls.attachMedia(video);
    } else {
      video.src = url;
      video.preload = "auto";
      video.muted = true;
      video.load();
    }
    if(story?.thumbnail) {
      imageLoaded(story?.thumbnail)
    }
  };

  useEffect(() => {
    for(let i=0; i< stories.length; i++) {
      if(stories[i]?.videoByteType === 'question') {
        console.log(stories[i]?.questionData, "questionData questionData");
        stories[i].next = next
        stories[i].setNext = false
      }
      
      if(stories[i]?.streamVideo) {
        stories[i].next = next;
        stories[i].responseId = responseIDRef
      }
      stories[i].responseId = responseIDRef
    }
    console.log("multiLayer multiLayer", stories);
    
    for (let i = currentId+1; i <= currentId+1; i++) {
      const action = stories[i]?.type === "video" ? videoLoaded : imageLoaded;
            action(stories[i]?.url, stories[i]?.streamVideo, stories[i]);
      }
  }, [currentId]);

  console.log(responseIDRef, "responseIDRef responseIDRef responseIDRef responseIDRef");

  useEffect(() => {
    stopwatch.start();
    const storyObj: any = {};
    stories.forEach((value: any, index: number) => {
      storyObj[`screen_${index}`] = 0;
    });
    const params = {
      agent: navigator?.userAgent,
      reportId: route?.id,
      reportType: 'report'
    }
    // getScreenSet(params);
  }, []);

  const getScreenSet = async (params: any) => {
    const response = await screenView<any>("POST", `screenviews`,params);
    if (response?.responseObj?.data) {
      screenViewIDRef.current = response?.responseObj?.data;
    }
  }
  console.log(currentIDRef, "screenViewIDRef", currentId);

  useEffect(() => {
    if (typeof currentIndex === "number") {
      if (currentIndex >= 0 && currentIndex < stories.length) {
        setCurrentIdWrapper(() => currentIndex);
      } else {
        console.error(
          "Index out of bounds. Current index was set to value more than the length of stories array.",
          currentIndex
        );
      }
    }
  }, [currentIndex]);

  useLayoutEffect(() => {
    if (toggle) {
      // debouncePause()
      toggleState("pause", true);
    }
  }, [toggle]);

  useEffect(() => {
    if (typeof isPaused === "boolean") {
      setPause(isPaused);
    }
  }, [isPaused]);

    useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      // screenView<any>("PUT", `screenviews/${screenViewIDRef.current}`);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const isClient = typeof window !== "undefined" && window.document;
    if (
      isClient &&
      typeof keyboardNavigation === "boolean" &&
      keyboardNavigation
    ) {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [keyboardNavigation]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "ArrowLeft") {
      previous();
    } else if (e.key === "ArrowRight") {
      next();
    }
  };

  const toggleState = (action: string, bufferAction?: boolean) => {
    console.log("setPause comming", action);
    setPause(action === "pause");
    setBufferAction(!!bufferAction);
  };

  const setCurrentIdWrapper = (callback: any) => {
    setCurrentId(callback);
  };

  const previous = () => {
    console.log("previous previous");
    setCurrentIdWrapper((prev: any) => {toggleState('pause',true); return prev > 0 ? prev - 1 : prev});
  };

  const next = () => {
    // Check if component is mounted - for issue #130 (https://github.com/mohitk05/react-insta-stories/issues/130)
    console.log("next next next comming comming comming", currentId);
    
    if (isMounted()) {
      if (loop) {
        updateNextStoryIdForLoop();
      } else {
        // if(stories[currentId]?.videoByteType !== 'question') {
          updateNextStoryId();
        // }
      }
    }
  };

  const updateNextStoryIdForLoop = () => {
    setCurrentIdWrapper((prev: any) => (prev + 1) % stories.length);
  };

  const updateScreenView = async (params:any)=>{
    // await screenView<any>("PUT", `screenviews/${screenViewIDRef.current}/data`,params)
    console.log(stories[currentId]?.questionData, "quesdfmsnsnsdfjsnsjf");
    if(stories[currentId].videoByteType !== 'question') {
      const body = { 
        section:{
          videoClip: stories[currentId]?.questionData?._id,
          answer: "Viewed",
          duration: params?.duration
        },
        collectionId: stories[currentId]?.collectionId,
      }
      if(!responseIDRef.current) {
        await screenView<any>("POST", `response`,{...body, agent: navigator?.userAgent}).then((data:any) =>{
            responseIDRef.current = data?.responseObj?.data?._id
        })
      } else {
        await screenView<any>("PUT", `response/${responseIDRef?.current}`,body)
      }
    }
  }

  const updateNextStoryId = () => {
    const screen = stopwatch.getElapsedTime();
    console.log("comming comming comming commingcomming comming");
    setCurrentIdWrapper((prev: any) => {
      if (prev < stories.length - 1) {
        const params = {
          screenIndex: prev,
          duration: screen,
          productId: stories[currentId].productID
        }

        if (prev !== previousIndex && prev !== 0){
          updateScreenView(params)
        }
        previousIndex=prev
        toggleState("pause", true);     
        stopwatch.reset();
        stopwatch.start();
        return prev + 1;
      }
      
      const params = {
        screenIndex: prev,
        duration: screen,
        productId:stories[currentId].productID
      }
      if (prev !== previousIndex && prev !== 0){
        updateScreenView(params)
      }
      previousIndex=prev
      stopwatch.reset();
      stopwatch.start();
      return prev;
    })
  };

  const debouncePause = (event:any) => {
    if (event.target.tagName === 'DIV') {
      
      event.preventDefault();
      console.log("DIV", "debouncePause");
      mousedownId.current = setTimeout(() => {
        toggleState("pause", true);
      }, 200);
    }
  };

  const mouseUp = (type: string) => (
    e: React.MouseEvent | React.TouchEvent
  ) => {
    console.log(pause, "pause pause");
    e.preventDefault();
    mousedownId.current && clearTimeout(mousedownId.current);
    if (pause && !stories[currentId]?.streamVideo) {
      toggleState("play", true);
    }else {
      type === "next" ? next() : previous();
    }
  };

  const getVideoDuration = (duration: number) => {
    setVideoDuration(duration * 1000);
  };

  console.log("stories stories pause", stories[currentId]);
  

  return (
    <div
    style={{
      ...styles.container,
      ...storyContainerStyles,
      ...{ width, height },
      background: currentId !==0 ? "rgb(17, 17, 17)":'transparent'
    }}
    >
      <ProgressContext.Provider
        value={{
          bufferAction,
          videoDuration,
          currentId,
          pause,
          next: stories[currentId].videoByteType === "question" ? () => {} : next,
        }}
        >
     {(currentId !== 0 && currentId !== stories.length-1) && <ProgressArray />}
      </ProgressContext.Provider>
      <Story
        action={toggleState}
        bufferAction={bufferAction}
        playState={pause}
        story={stories[currentId]}
        getVideoDuration={getVideoDuration}
      />
      {!preventDefault && (
        <div style={{...styles.overlay}} 
        >
          <div
            style={{ width: "40%",zIndex: (stories[currentId].videoByteType ==="question" || stories[currentId].videoByteType ==="product") ? 999 :9999999,flexDirection: "column" }}
            onTouchStart={debouncePause}
            onTouchEnd={mouseUp("previous")}
            onMouseDown={debouncePause}
            onMouseUp={mouseUp("previous")}
          />
          <div
            style={{ width: "60%",zIndex: (stories[currentId].videoByteType ==="question" || stories[currentId].videoByteType ==="product") ? 999 :9999999,flexDirection: "column"}}
            onTouchStart={debouncePause}
            onTouchEnd={mouseUp("next")}
            onMouseDown={debouncePause}
            onMouseUp={mouseUp("next")}
        />
          </div>
      )}
    </div>
  );
}
export default Container;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as const,
    background: "#111",
    position: "relative" as const,
  },
  overlay: {
    position: "absolute" as const,
    height: "inherit",
    width: "inherit",
    display: "flex",
    webkitUserSelect:"none"
  },
};