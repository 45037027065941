export function isIpadOS() {
  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform)
  );
}

declare global {
	interface Window {
		WebViewJavascriptBridge: any;
		WKWebViewJavascriptBridge: any;
		WKWVJBCallbacks: any;
		webkit: any;
	}
}

export const setupWebViewJavascriptBridge = (callback: any) => {
	if (/android/.test(navigator.userAgent.toLowerCase())) {
		if (window?.WebViewJavascriptBridge) {
			callback(window.WebViewJavascriptBridge);
		} else {
			document.addEventListener(
				"WebViewJavascriptBridgeReady",
				function () {
					callback(window.WebViewJavascriptBridge);
				},
				false
			);
		}
	} else if (
		/ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase()) ||
		isIpadOS()
	) {
		if (window?.WKWebViewJavascriptBridge) {
			return callback(window?.WKWebViewJavascriptBridge);
		}
		if (window.WKWVJBCallbacks) {
			return window.WKWVJBCallbacks.push(callback);
		}
		window.WKWVJBCallbacks = [callback];
		window?.webkit?.messageHandlers?.iOS_Native_InjectJavascript.postMessage(
			null
		);
	}
};