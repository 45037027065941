import React, { useEffect } from "react";
import { Renderer, Tester } from "../../../interfaces";

export const renderer: Renderer = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    props.action("play", true);
  }, [props.story]);

  const Content = props.story.originalContent;

  if (!Content) {
    return <div>Error: Could not find content for this story.</div>;
  }

  return <Content {...props} />;
};

export const tester: Tester = (story) => {
  return {
    condition: !!story.content,
    priority: 2,
  };
};

export default {
  renderer,
  tester,
};
