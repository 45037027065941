import React from "react";
import "./stripe.scss";
import Lottie from "react-lottie";
import * as notFound from "./404.json";
import Logo from "../../images/walkthru_image.png";

export const Unknown = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const height = window.innerWidth > 640 ? 500 : 300;
  return (
    <div className="bg-gray">
      <div className="stripe-container">
        <div
          className="center-container"
          style={{ position: "sticky", top: 20, background: "#fff", flex: 0 }}
        >
          <img
            src={Logo}
            alt="logo"
            style={{ width: "150px", objectFit: "scale-down" }}
          />
        </div>
        <Lottie options={defaultOptions} height={height} width={"100%"} />
        <div className="stripe-content">
          <p className="title stripe-failed-color">Not found!</p>
          <p className="content">We couldn't found your record.</p>
          <p className="line" />
        </div>
      </div>
    </div>
  );
};
