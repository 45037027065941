import { useEffect, useState } from "react";
// import Stories from "react-insta-stories";
import ReactInstaStories from "../story";
import { useParams } from "react-router-dom";
import { SpinnerLoader } from "../../common/Global.Style";
import "./videoByte.scoped.scss";
import { setupWebViewJavascriptBridge } from "../jsBridge/index";
// import LocationIcon from "../../images/location-icon.png";
import Logo from "../../images/new-report.png";
import screenView from "../../api/screenview";
import apiPlugin from "../../api/apiPlugin";
import Lottie from "react-lottie";
import * as success from "../../components/v2/success.json";


// import introImage from "../../images/intro-image.png";

// const slideUpAndDown = (slideUp: boolean) => {
//   let slide: any = document.getElementById("slide");
//   let productContent: any = document.getElementById("product-content");
//   let shop: any = document.getElementById("shop");
//   let closebtn: any = document.getElementById("close");
//   closebtn.style.display = "none";
//   shop.addEventListener("click", slideUp);

//   if (slideUp) {
//     slide.style.marginTop = "-630px";
//     closebtn.style.display = "block";
//     productContent.style.padding = "20px";
//   } else {
//     closebtn.style.display = "none";
//     slide.style.marginTop = "0%";
//   }
// }

const getAlphabeticLetter =(index:number) =>{
  return String.fromCharCode(65 + index);
}

const getRomanNumeral = (index:number) => {
  const romanNumerals = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX"]; 
  if (index >= 0 && index < romanNumerals.length) {
    return romanNumerals[index];
  }
  return getAlphabeticLetter(index);
}

const multiSelectLayout = ({ data,updatedButton,handleUpdateSlide}: any) => {
  return (
    <div className="form-content">
   <div className="option-layout">
    {console.log(updatedButton,"updatedButtonupdatedButton")
    }
    {  data?.section?.options.map((opt:any, i:number) => {
      
      const alphabeticLetter = getRomanNumeral(i);
          return (
            <button
              key={i}
              className={`option-button ${updatedButton.index === i && updatedButton.class ? 'updated-button' : ''}`}
              onClick={() =>{
                console.log("multi",opt,data._id,i, "otp topasdfs");
                
                      handleUpdateSlide("multi",opt,data._id,i)
            }         
            }
            >
              <div className="option-text">     
                <span className="alpha-style">{alphabeticLetter}</span>{opt}</div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

const ButtonLayout = ({ data,updatedButton,handleUpdateSlide}: any) => {
  const openLinkInBrowser = () => {
    const link = data?.section?.btnLink.includes("https://")?`${data?.section?.btnLink}/linking`: `https://${data?.section?.btnLink}/linking`
    const isMobileBrowser = /Mobi|Android|iPhone|iPad|iPod|Windows Phone|Tablet/i.test(
      navigator.userAgent
    );
    const isAndroid = /android/i.test(navigator.userAgent);
    const isIos = /iPad|iPhone|iPod/i.test(navigator.userAgent);

    if (link && isMobileBrowser && isAndroid) {
      window.open(link.replace("/linking",""),'_blank')
      // window.location.href=link;
      handleUpdateSlide("button","clicked",data._id)
    }else if(link && isMobileBrowser && isIos){
      // window.open(link,'_blank')
      window.open(link.replace("/linking",""),'_blank')

      // window.location.href=link;
    handleUpdateSlide("button","clicked",data._id)
    }
    else{
    handleUpdateSlide("button","clicked",data._id)
    window.open(link.replace("/linking",""),'_blank')
    }
  };
  
  return (
      <div className="text-layout">
  <div  onClick={() => { openLinkInBrowser()}} className={`button-style ${updatedButton.class ? 'updated-button' : ''}`}>
    {data?.section?.btnLabel}</div>
      </div>
  );
};

const TextLayout = ({ data,updatedButton,handleUpdateSlide,setResponse, textField, setTextField}: any) => {
  return (
      <form className="text-layout" onSubmit={(e) =>{
        e.preventDefault()
        handleUpdateSlide("text")
      } }>    
     <input type="text" value={textField} required placeholder="Type your answer here" onChange={(e)=> {
                setResponse((prevState:any) => ({...prevState,answer:e.target.value,questionId:data._id}));
                setTextField(e.target.value);
     }} size={100} className="text-style"/>
     <button type="submit" className={`button-style ${updatedButton.class ? 'updated-button' : ''}`}>Submit</button>
      </form>
  );
};

const ImageLayout = ({ data,updatedButton,setResponse,setUpdatedButton,setOpenCamera,cameraIsOn,startCamera}: any) => {
  return (
 <button type="submit" className={`button-style ${updatedButton.class ? 'updated-button' : ''}`} onClick={() =>{
  if(cameraIsOn){
    startCamera()
  }else{
    setResponse((prevState:any) => ({...prevState,questionId:data?._id}))
                   setUpdatedButton({index:"",class:true})
                   setTimeout(() => {
                   setUpdatedButton({index:"",class:false})
                     setOpenCamera(true)            
                  },600)         
  }
 }}>Capture Image</button> 
  );
};

const FeedbackStar = ({ data,updatedButton,handleUpdateSlide}: any) => {
  const startGroup =[
    {starId:"rating-5",star:5},
    {starId:"rating-4",star:4},
    {starId:"rating-3",star:3},
    {starId:"rating-2",star:2},
    {starId:"rating-1",star:1},
  ]
  return (
          <div className="star-layout">  
          <h3 style={{color:"#fff"}}>RATE US</h3>
<div className="feedback">
  <div className="rating">
{startGroup.map((str,index) => {
  return(
< >
  <input type="radio" key={index}  name="rating" id={str.starId} />
    <label key={index} htmlFor={str.starId} onClick={() =>{
        handleUpdateSlide("star",str.star,data._id)
      }
    } className={`${updatedButton.class ? 'updated-button' : ''}`}></label>
    </> )
})  }
  </div>
</div>
</div>
  );
};

const FeedbackScale = ({ data,handleUpdateSlide}: any) => {
  const scaleGroup = [
   { scale:1,class:"btn-scale btn-scale-desc-1"},
   { scale:2,class:"btn-scale btn-scale-desc-2"},
   { scale:3,class:"btn-scale btn-scale-desc-3"},
   { scale:4,class:"btn-scale btn-scale-desc-4"},
   { scale:5,class:"btn-scale btn-scale-desc-5"},
   { scale:6,class:"btn-scale btn-scale-desc-6"},
   { scale:7,class:"btn-scale btn-scale-desc-7"},
   { scale:8,class:"btn-scale btn-scale-desc-8"},
   { scale:9,class:"btn-scale btn-scale-desc-9"},
   { scale:10,class:"btn-scale btn-scale-desc-10"},
  ]

  return (
<div className="scale-layout">
<h3 style={{color:"#fff"}}>RATE US</h3>
<div className="scale-btn">
        {scaleGroup.map((scale,index) =>{
       return( <button key={index} className={scale.class} onClick={() =>{
handleUpdateSlide("scale",scale.scale,data._id)
}
}>{scale.scale}</button>)})}
        </div>
      </div>
  );
};

const callHandler = (funName: any, dataJson: any, callback: any) => {
  setupWebViewJavascriptBridge(function (bridge: any) {
    bridge.callHandler(funName, dataJson, function (response: any) {
      callback && callback(response);
    });
  });
};

const pairDevice = () => {
  callHandler("pairDevice", "", () => { });
};

const seeMore = (cta: any, product: any) => {
  const { textCode, textPhone, callPhone, callCode, calendly } = cta;
  let isButtonEnable =
    textCode && textPhone && callPhone && callCode ? true : false;
  const calendlyPattern =
    /^((https?:\/\/)|(www\.)|(webcal:\/\/))[^\s/$.?#].[^\s]*$/i;

  return ({ close }: any) => (
    <div className="block">
      <div className="container product-bottom">
        <div id="product-content" className="product-container">
          <div className="product-page">
            <div className="product-block">
              <div>
                <div className="product-header">
                  <div className="arrow-down-block" onClick={close}>
                    <div className="arrow-down-container">
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="product-title">
                    <p>{product?.name}</p>
                  </div>
                </div>
                <div className="product-list">
                  {/* {product?.images?.map((img: string) => {
                    return ( */}
                  <div className="product">
                    <img
                      src={product?.images?.[0]?.url}
                      height="120px"
                      width="100px"
                      alt="devices"
                    />
                  </div>
                  {/* ); */}
                  {/* })} */}
                </div>
                <div>
                  <p className="title align-left">Description</p>
                  <p className="product-desc align-left">
                    {product?.description}
                  </p>
                </div>
              </div>
              <div className="action-btn">
                {isButtonEnable && (
                  <div className="button-grp">
                    <a
                      href={`tel:${callCode} ${callPhone}`}
                      className="call-btn"
                    >
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Call.png"
                          height="14px"
                          width="14px"
                          alt="call"
                        />
                      </span>
                      Call
                    </a>
                    <a
                      href={`sms:${textCode} ${textPhone}`}
                      className="sms-btn"
                    >
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Chat.png"
                          height="16px"
                          width="16px"
                          alt="chat"
                        />
                      </span>
                      SMS
                    </a>
                  </div>
                )}
                <div>
                  {calendlyPattern?.test(calendly) && (
                    <a
                      href={calendly}
                      target="_blank"
                      rel="noreferrer"
                      className="calender-btn"
                    // onClick={pairDevice}
                    >
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Calendar.png"
                          height="16px"
                          width="16px"
                          alt="calendar"
                        />
                      </span>
                      Calendly
                    </a>
                  )}
                </div>
                {console.log(product.btnLink?.length, "length")}

                {product.btnLink && product.btnLink?.length !== 0 && product.btnLabel && product?.btnLabel?.length !== 0 && (
                  <div>
                    <a
                      href={product?.btnLink}
                      rel="noreferrer"
                      target="_blank"
                      className="buy-btn"
                    >
                      {/* <div className="buy-btn"> */}
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Buy.png"
                          height="16px"
                          width="16px"
                          alt="buy"
                        />
                      </span>
                      {/* <button onClick={() => window.postMessage(product?.webUrl)} > */}
                      {product?.btnLabel ? product?.btnLabel : "Buy now"}
                      {/* </button> */}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const introPage = (name: string, companyLogo: string,coverImage: string, comLogo: string) => {
  return (
    <div className="intro-page" 
      style={{backgroundImage: `url(${coverImage})`}}
    >
    <div className="intro-mirror">
    {comLogo && <div className="com-logo">
          <img src={comLogo} alt="logo" />
        </div>}
    <div className="new-desc" style={{ textAlign: "center", paddingTop: '10px'}} >
          Here's your <br /> personalized
        </div>
        <div className="into-logo">
          <img src={"https://assets.bldghealth.net/company/logo/logo-new-walk.png"} alt="logo" />
        </div>
      </div>
      <div className="guide-next-btn" style={{ margin: "0 auto", marginTop: "30px" }}>
            <div style={{ fontSize: 18, fontWeight: 800, color: "#fff", textAlign: "center" }}>
              Tap Anywhere To Proceed
            </div>
          </div>
    </div>
  );
};

const getDeviceNameFromAgentName = (agent: string) => {
  if (agent?.includes('iPhone')) {
    return false;
  } else if (agent?.includes('Macintosh')) {
    return true;
  } else if (agent?.includes('Android')) {
    return false;
  } else {
    return true;
  }
};

const thankPage = () => {
  const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
  return (
    <div className="thank-page">
    <Lottie options={lottieOptions} height={400} width={"100%"} />
      <h2>
        Thank you!!!
      </h2>
      <p>Thank you for your time and assistance</p>
  </div>
  )
}

const seeMoreCollapsedComponent = ({ toggleMore, action, state }: any) => {
  return (
    <div
      className="shop-text"
      id="slide"
      onClick={() => {
        action("pause", true);
        toggleMore(true);
        state(true);
      }}
      onTouchStart={(e) => {
        e.preventDefault();
        action("pause", true);
        toggleMore(true);
        state(true);
      }}
    >
      <div className="slide-bar-par">
        <div
          className="arrow-up-container"
          onClick={() => {
            action("pause", true);
            toggleMore(true);
            state(true);
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            action("pause", true);
            toggleMore(true);
            state(true);
          }}
        >
          <div className="arrow-up"></div>
        </div>
      </div>
      <h6 id="shop" className="shop-btn">
        Shop now
      </h6>
      {/* <div className="slide-bar-par">
        <div className="slide-bar"></div>
      </div> */}
    </div>
  );
};

const VideoByte = () => {
  let { id }: any = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [slides, setSlides] = useState<any[]>([
    {
      url: "https://icloud-image-uploader.s3.amazonaws.com/report_images/6342464.jpg",
    },
  ]);

  const screenWidth = window.innerWidth >= 1201 ? 810 : "100vw";

  useEffect(() => {
    getWalkthruSlides();
  }, []);

  const getWalkthruSlides = async () => {
    let slide: any[] = [
      {
        url: "https://icloud-image-uploader.s3.amazonaws.com/report_images/6342464.jpg",
      },
    ];
    const response = await getWalkthruImages();
    console.log(response?.responseObj?.data, "response response");
    if (response?.responseObj && response?.responseObj?.data) {
      slide = []
      const { name, reportSettings, isTrial,customer, videoClips, _id, coverImage, logo, user } = response?.responseObj?.data;
      let {streamVideo} = user
      if(getDeviceNameFromAgentName(navigator?.userAgent)) {
        streamVideo = false
      }
      
      // const slides = ["https://stg-assets.bldghealth.net/testing/hls-test-new-HLS-apple-02-reorder-long-video/dust-free-active-1-final-s3_0vs1S1q4.m3u8", "https://stg-assets.bldghealth.net/testing/hls-test-new-HLS-apple-reorder-long-video-03/dust-free-03.m3u8", "https://stg-assets.bldghealth.net/testing/hls-test-new-HLS-apple-reorder-long-video-02/dust-free-02.m3u8", "https://stg-assets.bldghealth.net/testing/hls-test-new-HLS-apple-reorder-long-video-01/dust-free-hepa-01.m3u8"]
      const companyLogo = reportSettings?.logo ? reportSettings?.logo : ''
      const covImage = coverImage ? coverImage : videoClips?.[0]?.type !== "product" ? videoClips?.[0]?.media?.thumbnail : videoClips?.[0]?.product?.bannerImage?.url 
      slide.push({
        content: () => {
          return introPage(name || customer?.name, companyLogo, covImage, logo?.url);
        },
      });
        videoClips?.forEach((item: any, index: number) => {
          if (item.type === 'product') {
            const url = item?.product?.video ? streamVideo ? item?.product?.video?.streamUrl : item?.product?.video?.videoUrl : item?.product?.bannerImage?.url
            slide.push({
              id: index,
              productID: item?.product ? item?.product?._id : "",
              url,
              type: item?.product?.video ? "video" : "image",
              className: "image-layout",
              text: item?.overlay ? item?.overlay : '',
              overlayImg: item?.image,
              seeMore: item?.product?.showCTA ? seeMore(item?.product?.reportSettings || {}, item?.product): "",
              showBtn: !item?.product?.showCTA,
              btnLabel: item?.product?.btnLabel,
              btnLink: item?.product?.btnLink,
              duration: 8000,
              seeMoreCollapsed: seeMoreCollapsedComponent,
              logo: companyLogo,
              isRestricted: isTrial,
              videoByteType: item.type,
              collectionId: _id,
              questionData: item,
              streamVideo,
              thumbnail: item?.product?.bannerImage?.url
            })
          } else if(item.type === 'explainer') {
            const url = streamVideo ? item?.media?.streamUrl : item?.media?.videoUrl
            slide.push({
              id: index,
              url,
              type: item?.media?.type,
              className: "image-layout",
              isRestricted: isTrial,
              videoByteType: item.type,
              collectionId: _id,
              questionData: item,
              streamVideo,
              thumbnail: item?.thumbnail?.videoUrl
            })
          } else if (item.type === 'question') {
            const url = streamVideo ? item?.media?.streamUrl : item?.media?.videoUrl
            slide.push({
              id: index,
              url,
              type: item?.media?.type,
              className: "image-layout",
              isRestricted: isTrial,
              videoByteType: item.type,
              questionData: item,
              collectionId: _id,
              streamVideo,
              thumbnail: item?.thumbnail?.videoUrl
            })
          }
        })

       slide.push({
        content: () => {
          return thankPage();
        },
      });
      setSlides(slide);
    }
    setIsLoading(false);
  };

  // {questions[currentSlide].answerType === "multi" &&  
  //         <div className="intract-container"  data-aos="zoom-in-up" data-aos-once="true">
  //        <MultiSelectLayout  updatedButton={updatedButton}  data={questions[currentSlide]}  handleUpdateSlide={handleUpdateSlide}/>
  //        </div>}

  const getWalkthruImages = async () => {
    const explainer = window.location.pathname.includes('explainer');
    const walkthru = window.location.pathname.includes('explainer');
    const lab = window.location.pathname.includes('lab');
    if (explainer) {
      return screenView<any>("GET", `reports/${id}`);
    } else if (lab) {
      return apiPlugin<any>("GET", `lab-test/${id}`);
    } else if (walkthru) {
      return screenView<any>("GET", `solutions/${id}`);
    } else {
      return screenView<any>("GET", `users/shared-collection/${id}`)
    }
  };

  if (isLoading) {
    return (
      <div>
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div className="insta-story">
      <ReactInstaStories
        stories={slides}
        keyboardNavigation
        defaultInterval={8000}play-btn
        loader={<SpinnerLoader />}
        width={screenWidth}
        height={"100dvh"}
      />
    </div>
  );
};

export default VideoByte;
