import { SET_TEST } from "../actions/actionTypes";

export const testReducer = (state = {}, action: any) => {
    switch (action.type) {
        case SET_TEST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
