import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(sagaMiddleware)) // add redux chrome plugin
);

export const persistor = persistStore(store); // store state value in local-storage

export default { store, persistor };
