import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

// import { theme } from "./theme/CustomTheme";
import Shared from "./common/Shared";
import { URL } from "./components/Navbar/routes";

import "./MainStyle.scss";
import "./App.css";
import { Success } from "./components/stripe/success";
import { Failed } from "./components/stripe/failed";
import ExplainerStory from "./components/explainer/explainer";
import Join from "./components/technician/join";
import Form from "./components/customer/form";
import Question from "./components/v2/questions";
import QuestionSuccess from "./components/v2/questionSuccess";
import { Unknown } from "./components/stripe/unknown";
import { Expired } from "./components/stripe/expired";
import VideoByte from "./components/videoByte/videoByte";
import QrCode from "./components/QrCode/qrCode";
// import QrCode from "./components/QrCode/qrCode";

function App() {
  useEffect(() => {
    document.title = Shared.title;
  }, []);

  return (
    // <ThemeProvider theme={theme}>
    <div className="sans-serif">
      <Switch>
        <Route path={URL.EXPLAINER_STORY} component={ExplainerStory} />
        <Route path={URL.WALKTHRU_STORY} component={ExplainerStory} />
        <Route path={URL.LAB_STORY} component={ExplainerStory} />
        <Route path={URL.TECHNICIAN_JOIN} component={Join} />
        <Route path={URL.SUCCESS} component={Success} />
        <Route path={URL.FAILED} component={Failed} />
        <Route path={URL.CUSTOMER_FORM} component={Form} />
        <Route path={URL.QUESTIONS_FORM} component={Question} />
        <Route path={URL.RESPONSE_SUCCESS} component={QuestionSuccess} />
        <Route path={URL.UNKNOWN} component={Unknown} />
        <Route path={URL.EXPIRED} component={Expired} />
        <Route path={URL.VIDEOBYTE} component={VideoByte} />
        <Route path={URL.QRCODE} component={QrCode} />
      </Switch>
    </div>
    // </ThemeProvider>
  );
}

export default App;
