import { combineReducers } from "redux";
import { userReducer } from "./user";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { testReducer } from "./test";

const persistConfig = {
	key: "root",
	storage, // used local-storage
	whitelist: ["user", "test"], // add reducers name you want to persistance
};

const rootReducer = combineReducers({
	user: userReducer,
	test: testReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default persistReducer(persistConfig, rootReducer);
