import React from "react";
import "./stripe.scss";
import Lottie from "react-lottie";
import * as success from "./success.json";
import { setupWebViewJavascriptBridge } from "../jsBridge";

export const Success = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: success,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const callHandler = (funName: any, dataJson: any, callback: any) => {
    setupWebViewJavascriptBridge(function(bridge: any) {
      bridge.callHandler(funName, dataJson, function(response: any) {
        callback && callback(response);
      });
    });
  };

  const openStore = () => {
    callHandler("openStore", "", () => {});
  };
  const height = window.innerWidth > 640 ? 500 : 300;
  return (
    <div className="bg-gray">
      <div className="stripe-container">
        <div className="stripe-header stripe-success">
          <h4>Payment Confirmation</h4>
        </div>
        <Lottie options={defaultOptions} height={height} width={"100%"} />
        <div className="stripe-content">
          <p className="title stripe-color">Payment Successful</p>
          <p className="content">Your payment has been processed!</p>
          <p className="line" />
        </div>
      </div>
    </div>
  );
};
