class Stopwatch {
    constructor() {
        this.startTime = 0;
        this.running = false;
        this.elapsedTime = 0;
    }

    start() {
        if (!this.running) {
            this.startTime = new Date().getTime() - this.elapsedTime;
            this.running = true;
            this.timer = setInterval(() => {
                this.elapsedTime = new Date().getTime() - this.startTime;
            }, 10);
        }
    }

    stop() {
        if (this.running) {
            clearInterval(this.timer);
            this.running = false;
        }
    }

    reset() {
        this.stop();
        this.elapsedTime = 0;
    }

    getElapsedTime() {
        return this.elapsedTime;
    }
}

// Usage
export const stopwatch = new Stopwatch();