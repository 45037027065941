import Lottie from "react-lottie";
import * as success from "./success.json";
import "./question.scss";

const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const QuestionSuccess = () => {
  return (
    <div className="bg-color">
      <div className="player-container">
   <Lottie options={lottieOptions} height={500} width={"100%"} />
<div  style={{display:"flex",gap:20, alignItems:"center",flexDirection:"column",justifyContent:"center"}}>
  <h4 style={{ color: "#979695", textAlign:"center",
   fontWeight:600 }}>Thank you for submitting your response</h4>
   <p style={{ color: "#27ae60",
      fontSize:16 }}>Responses Submitted Successfully!</p>
      </div>
</div>
</div>
  );
};

export default QuestionSuccess;
