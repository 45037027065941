import { useEffect, useState } from "react";
// import Stories from "react-insta-stories";
import ReactInstaStories from "../story";
import { useParams } from "react-router-dom";
import { SpinnerLoader } from "../../common/Global.Style";
import "./explainer.scoped.scss";
import { setupWebViewJavascriptBridge } from "../jsBridge/index";
// import LocationIcon from "../../images/location-icon.png";
import Logo from "../../images/new-report.png";
import screenView from "../../api/screenview";
import apiPlugin from "../../api/apiPlugin";
// import introImage from "../../images/intro-image.png";

// const slideUpAndDown = (slideUp: boolean) => {
//   let slide: any = document.getElementById("slide");
//   let productContent: any = document.getElementById("product-content");
//   let shop: any = document.getElementById("shop");
//   let closebtn: any = document.getElementById("close");
//   closebtn.style.display = "none";
//   shop.addEventListener("click", slideUp);

//   if (slideUp) {
//     slide.style.marginTop = "-630px";
//     closebtn.style.display = "block";
//     productContent.style.padding = "20px";
//   } else {
//     closebtn.style.display = "none";
//     slide.style.marginTop = "0%";
//   }
// }

const callHandler = (funName: any, dataJson: any, callback: any) => {
  setupWebViewJavascriptBridge(function (bridge: any) {
    bridge.callHandler(funName, dataJson, function (response: any) {
      callback && callback(response);
    });
  });
};

const pairDevice = () => {
  callHandler("pairDevice", "", () => { });
};

const seeMore = (cta: any, product: any) => {
  const { textCode, textPhone, callPhone, callCode, calendly } = cta;
  let isButtonEnable =
    textCode && textPhone && callPhone && callCode ? true : false;
  const calendlyPattern =
    /^((https?:\/\/)|(www\.)|(webcal:\/\/))[^\s/$.?#].[^\s]*$/i;

  return ({ close }: any) => (
    <div className="block">
      <div className="container product-bottom">
        <div id="product-content" className="product-container">
          <div className="product-page">
            <div className="product-block">
              <div>
                <div className="product-header">
                  <div className="arrow-down-block" onClick={close}>
                    <div className="arrow-down-container">
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="product-title">
                    <p>{product?.name}</p>
                  </div>
                </div>
                <div className="product-list">
                  {/* {product?.images?.map((img: string) => {
                    return ( */}
                  <div className="product">
                    <img
                      src={product?.images?.[0]}
                      height="120px"
                      width="100px"
                      alt="devices"
                    />
                  </div>
                  {/* ); */}
                  {/* })} */}
                </div>
                <div>
                  <p className="title align-left">Description</p>
                  <p className="product-desc align-left">
                    {product?.description}
                  </p>
                </div>
              </div>
              <div className="action-btn">
                {isButtonEnable && (
                  <div className="button-grp">
                    <a
                      href={`tel:${callCode} ${callPhone}`}
                      className="call-btn"
                    >
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Call.png"
                          height="14px"
                          width="14px"
                          alt="call"
                        />
                      </span>
                      Call
                    </a>
                    <a
                      href={`sms:${textCode} ${textPhone}`}
                      className="sms-btn"
                    >
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Chat.png"
                          height="16px"
                          width="16px"
                          alt="chat"
                        />
                      </span>
                      SMS
                    </a>
                  </div>
                )}
                <div>
                  {calendlyPattern?.test(calendly) && (
                    <a
                      href={calendly}
                      target="_blank"
                      rel="noreferrer"
                      className="calender-btn"
                    // onClick={pairDevice}
                    >
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Calendar.png"
                          height="16px"
                          width="16px"
                          alt="calendar"
                        />
                      </span>
                      Calendly
                    </a>
                  )}
                </div>
                {console.log(product.btnLink?.length, "length")}

                {product.btnLink && product.btnLink?.length !== 0 && product.btnLabel && product?.btnLabel?.length !== 0 && (
                  <div>
                    <a
                      href={product?.btnLink}
                      rel="noreferrer"
                      target="_blank"
                      className="buy-btn"
                    >
                      {/* <div className="buy-btn"> */}
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Buy.png"
                          height="16px"
                          width="16px"
                          alt="buy"
                        />
                      </span>
                      {/* <button onClick={() => window.postMessage(product?.webUrl)} > */}
                      {product?.btnLabel ? product?.btnLabel : "Buy now"}
                      {/* </button> */}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const introPage = (name: string, companyLogo: string) => {
  return (
    <div className="intro-page">
    <div className="intro-border">
      <div className="intro-banner">
        {companyLogo && <div className="into-company">
          <img src={companyLogo} alt="logo" />
        </div>}
        <div className="new-desc" style={{ textAlign: "center"}} >
          Here's your <br /> personalized
        </div>
        <div className="into-logo">
          <img src={"https://assets.bldghealth.net/company/logo/logo-new-walk.png"} alt="logo" />
        </div>
      </div>
      <div className="intro-add">

        <div className="align-item-center" style={{ padding: 10, width: "100%", position: 'relative' }}>
          <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', padding: "20px 0px"}}>
            <img
              className="icon"
              src={"https://assets.bldghealth.net/technician/tap-1.png"}
              alt="intro"
            />
          </div>
          <div className="guide-next-btn" style={{ margin: "0 auto" }}>
            <div style={{ fontSize: 18, fontWeight: 800, color: "#fff", textAlign: "center" }}>
              Tap Anywhere To Proceed
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

const seeMoreCollapsedComponent = ({ toggleMore, action, state }: any) => {
  return (
    <div
      className="shop-text"
      id="slide"
      onClick={() => {
        action("pause", true);
        toggleMore(true);
        state(true);
      }}
      onTouchStart={(e) => {
        e.preventDefault();
        action("pause", true);
        toggleMore(true);
        state(true);
      }}
    >
      <div className="slide-bar-par">
        <div
          className="arrow-up-container"
          onClick={() => {
            action("pause", true);
            toggleMore(true);
            state(true);
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            action("pause", true);
            toggleMore(true);
            state(true);
          }}
        >
          <div className="arrow-up"></div>
        </div>
      </div>
      <h6 id="shop" className="shop-btn">
        Shop now
      </h6>
      {/* <div className="slide-bar-par">
        <div className="slide-bar"></div>
      </div> */}
    </div>
  );
};

const ExplainerStory = () => {
  let { id }: any = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [slides, setSlides] = useState<any[]>([
    {
      url: "https://icloud-image-uploader.s3.amazonaws.com/report_images/6342464.jpg",
    },
  ]);

  const screenWidth = window.innerWidth >= 1201 ? 810 : "100vw";

  useEffect(() => {
    getWalkthruSlides();
  }, []);

  const getWalkthruSlides = async () => {
    let slide: any[] = [
      {
        url: "https://icloud-image-uploader.s3.amazonaws.com/report_images/6342464.jpg",
      },
    ];
    const response = await getWalkthruImages();
    console.log(response?.responseObj?.data, "response response");
    if (response?.responseObj && response?.responseObj?.data) {
      slide = []
      const { slides, name, reportSettings, isTrial,customer } = response?.responseObj?.data;
      const companyLogo = reportSettings?.logo ? reportSettings?.logo : ''
      slide.push({
        content: () => {
          return introPage(name || customer?.name, companyLogo);
        },
      });
      slides?.forEach((item: any, index: number) => {
        if (item.contentType === 'product') {
          slide.push({
            id: index,
            productID: item?.product ? item?.product?._id : "",
            url: item?.url,
            type: item?.mediaType,
            className: "image-layout",
            text: item?.overlay ? item?.overlay : '',
            overlayImg: item?.image,
            seeMore: item?.product?.showCTA ? seeMore(item?.product?.reportSettings || {}, item?.product): "",
            showBtn: !item?.product?.showCTA,
            btnLabel: item?.product?.btnLabel,
            btnLink: item?.product?.btnLink,
            duration: 8000,
            seeMoreCollapsed: seeMoreCollapsedComponent,
            logo: companyLogo,
            isRestricted: isTrial,
          })
        } else if (item.contentType === 'media') {
          slide.push({
            id: index,
            url: item?.url,
            type: item?.mediaType || "video",
            className: "image-layout",
            text: item?.overlay ? item?.overlay : '',
            overlayImg: item?.image,
            logo: companyLogo,
            isRestricted: isTrial,
            showBtn: item?.showBtn,
            btnLabel: item?.btnLabel,
            btnLink: item?.btnLink,
          })
        }
      })
      setSlides(slide);
    }
    setIsLoading(false);
  };

  const getWalkthruImages = async () => {
    const explainer = window.location.pathname.includes('explainer');
    const lab = window.location.pathname.includes('lab');
    if (explainer) {
      return screenView<any>("GET", `reports/${id}`);
    } else if (lab) {
      return apiPlugin<any>("GET", `lab-test/${id}`);
    } else {
      return screenView<any>("GET", `solutions/${id}`);
    }
  };

  if (isLoading) {
    return (
      <div>
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div className="insta-story">
      <ReactInstaStories
        stories={slides}
        keyboardNavigation
        defaultInterval={8000}
        loader={<SpinnerLoader />}
        width={screenWidth}
        height={"100vh"}
      />
    </div>
  );
};

export default ExplainerStory;
